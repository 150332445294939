import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';


import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';

import './orderViewDetail.css';
import OrderReview from '../review/OrderReview';

export default function OrderViewDetail(props) {
    const [searchParams] = useSearchParams();
    const [curData, setCurData] = useState(null);

    const order_id = searchParams.get("id");

    const navigate = useNavigate();

    const auth = useAuth();
    if (auth.user == null) {
        auth.logOut();
        // return;
    }



    useEffect(() => {

        if (!auth.user) {
            auth.logOut();
            return;
        }

        fetch("/api/orders/" + order_id, {
            method: "GET",
            headers: getHeaders(auth),
            //body: JSON.stringify(data),
        })
            .then((response) => response.json())
            //.then((jsonData) => addId(jsonData, "client_id"))
            .then((data) => {
                console.debug("data", data);
                if (data["result"]) {
                    auth.logOut();
                } else {
                    let orderData = data.data;
                    orderData['order_id'] = data['order_id'];
                    orderData['order_state'] = data['state'];
                    let state_str = "";
                    switch (parseInt(data['state'] + "")) {
                        case 10:
                            state_str = "New order";
                            break;
                        case 20:
                            state_str = "Accepted";
                            break;
                        case 30:
                            state_str = "Approved";
                            break;
                        case 31:
                            state_str = "Request to cancel";
                            break;
                        //----------------------------
                        case 40:
                            state_str = "Processed";//"Put in PB";
                            break;
                        case 60:
                            state_str = "Sent"; //"Put in SB";
                            break;
                        case 90:
                            state_str = "Received";
                            break;
                        default:
                            state_str = data['state']
                    }
                    orderData['state_str'] = state_str


                    orderData.order_infos.forEach(element => {
                        element['shirtConfig'] = element.data;
                        element['num_of_shirt'] = element['quantity'];
                    });

                    orderData['orderItems'] = orderData['order_infos'];
                    orderData['tailorshop'] = { tailorshop_id: auth.user.tailorshop_id, short_name: auth.user.short_name };




                    setCurData(orderData);


                }
            })
            .catch((error) => console.log(error));
        //fetchData();
    }, []);

    return (<>
        {curData !== null && <>
            <OrderReview orderData={curData} />

        </>
        }

    </>
    );
}

