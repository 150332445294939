import React, { Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import LoginForm from './components/login/login';
import SelectParts from './components/orders/shirtconfig/SelectParts';

import OrdersMan from './components/orders/orderman/ordersMan';
import AuthProvider, { useAuth } from "./hooks/AuthProvider";
import PrivateRoute from "./router/route";

import './App.css';

import MainNavbar from './components/navbar/MainNavbar';
import TailorShopReg from './components/tailorshop/TailorShopReg';
import BodyMeasurement from './components/orders/measurements/BodyMeasurement';
import ClientProfile from './components/client/clientprofile/ClientProfile';
import OrderSteps from './components/orders/neworder/OrderSteps';
import FabricSelect from './components/orders/fabricselect/FabricSelect';
import UserProfile from './components/profile/userprofile';
import Forgotpassword from './components/login/ForgotPassword';
import ResetPassword from './components/login/ResetPassword';
import SyslogView from './admincomps/syslog/syslogview';
import OrderViewDetail from './components/orders/orderviewdetail/orderViewDetail';
import MainReport from './components/reports/mainreport/MainReport';
import ShopUsersMan from './admincomps/shopusers/ShopUsersMan';
import ReviewOrders from './admincomps/shopusers/revieworders/revieworder';
import ApproveOrders from './admincomps/shopusers/approveorders/approveorders';
import { useNavigate } from "react-router-dom";
import DashBoard from './components/dashboard/maindashboard/DashBoard';
import SelectClient from './components/orders/OrderSteps/SelectClient/SelectClient';

import ShirtMeasure from './components/orders/OrderSteps/BodyMeasure/ShirtMeasure';
import SelectFabric from './components/orders/OrderSteps/SelectFabric/SelectFabric';
import DesignShirt from './components/orders/OrderSteps/ShirtConfig/DesignShirt';
import OrderInfo2 from './components/orders/OrderSteps/OrderInfo/OrderInfo';
import NewOrderSubmit from './components/orders/OrderSteps/NewOrderSubmit/NewOrderSubmit';
import SubmitResult from './components/orders/OrderSteps/SubmitResult/SubmitResult';
import TailorShopConfig from './components/tailorshop/TailorShopConf';
import ReceivedOrders from './admincomps/shopusers/receivedorders/ReceivedOrders';


const Home = () => {
  const navigate = useNavigate();

  const auth = useAuth();

  return (
    <div>
      {auth.user && <DashBoard />}

      {!auth.user && <>
        <div className="container-md m-auto">
          <div className="wrap text-center1 my-auto home">
            <div className="title_sl mb-3 utmed">
              <span className="text" style={{ color: "#000000", fontSize: "55px", fontWeight: "700" }}>SOLUTION <br />FOR SMALL <br />ORDERS</span>
            </div>
            <div className="brief_sl mb-3 "><p>We produce shirts from small to large quantities:</p>

              <ul>
                <li>Top quality</li>
                <li>Good prices</li>
                <li>Fast delivery from 1 week</li>
              </ul>
            </div>
            <div className="brief_sl2 mb-3">Minimum quantity: 1 piece!</div>
            <div className="more_sl">
              <a className=" d-inline-block " href="/neworder">Get Started</a>
            </div>
            <div className="info_sl d-inline-block1 d-none mt-5">Yen Bien Co., Ltd</div>
          </div>
        </div>
      </>
      }
    </div>
  );
};

const About = () => {
  return (
    <>
      <div className="container p-3 p-lg-4 p-xl-5 home">

        <div className="add mb-3 d-flex item">
          <span className="icon d-none"><i className="fa fa-map-marker-alt "></i></span>
          <div>
            <strong>SHIRT PRODUCED BY SHIRTBYHAND (VIETNAM) PRODUCTION COMPANY LTD.</strong><br />
            <span>3rd Floor 301-303 Vu Xuan Thieu, Long Bien, Hanoi, Vietnam</span>
          </div>
        </div>

        <div className="add mb-3 d-flex item">
          <span className="icon d-none"><i className="fa fa-map-marker-alt "></i></span>
          <div>
            <strong className="d-block">PLATFORM RUN BY YEN BIEN COMPANY LTD</strong>
            <span>Nha Vuon Lo 20, Me Tri Ha Street, Du An Me Tri Ha, Me Tri Ward, Nam Tu Liem District, Hanoi, Vietnam</span>
          </div>
        </div>

        <div className="phone mb-3 item d-flex">


          <div>
            <strong className="d-block">Phone number</strong>
            <span>
              <a href="tel:+84 903444019">+84 903444019</a>
              - <a href="tel:+84 903444019 (WhatsApp/ Zalo)">(WhatsApp)</a>                                </span>
          </div>
        </div>
        <div className="email mb-0 item d-flex">
          <span className="icon d-none"><i className="far fa-envelope "></i></span>
          <div>
            <strong className="d-block">Email</strong>
            <span><a href="mailto:toan@yenbien.com">toan@yenbien.com</a></span>
          </div>
        </div>
      </div>

      {/* <div className="container p-3 p-lg-4 p-xl-5 home">
      <p className="text-uppercase title mb-3"><strong>YEN BIEN COMPANY LIMITED</strong></p>
      <div className="add mb-3 d-flex item">
        <span className="icon d-none"><i className="fa fa-map-marker-alt "></i></span>
        <div>
          <strong className="d-block">Address</strong>
          <span>Nha Vuon Lo 20, Me Tri Ha Street, Du An Me Tri Ha, Me Tri Ward, Nam Tu Liem District, Hanoi, Vietnam</span>
        </div>
      </div>
      <div className="phone mb-3 item d-flex">
        <span className="icon d-none"><i className="fa  fa-mobile-alt "></i></span>
        <div>
          <strong className="d-block">Phone number</strong>
          <span>
            <a href="tel:+84 903444019">+84 903444019</a>
            - <a href="tel:+84 866061497 (WhatsApp/ Zalo)">+84 866061497 (WhatsApp/ Zalo)</a>                                </span>
        </div>
      </div>
      <div className="email mb-0 item d-flex">
        <span className="icon d-none"><i className="far fa-envelope "></i></span>
        <div>
          <strong className="d-block">Email</strong>
          <span><a href="mailto:contact@yenbien.com">contact@yenbien.com</a></span>
        </div>
      </div>
    </div> */}

    </>

  );
};


const MainApp = () => {
  //const isAuthenticated = false;

  return (
    <Router>
      <Fragment>
        <AuthProvider>
          <MainNavbar />
          <Routes>
            <Route path="/login" element={<LoginForm />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/" element={<Home />} />
            <Route path="/renewpass" element={<Forgotpassword />} />
            <Route path="/resetpass" element={<ResetPassword />} />
            <Route element={<PrivateRoute />}>
              <Route exact path='/selectparts' element={<SelectParts />} />
              <Route exact path='/ordersman' element={<OrdersMan />} />
              <Route exact path='/tailorshopreg' element={<TailorShopReg />} />
              <Route exact path='/tailorshopcfg' element={<TailorShopConfig />} />

              <Route exact path='/shopusers' element={<ShopUsersMan />} />
              <Route exact path='/bodymeasure' element={<BodyMeasurement />} />
              <Route exact path='/clientprofile' element={<ClientProfile />} />

              <Route exact path='/orderdetail' element={<OrderViewDetail />} />
              <Route exact path='/fabrics' element={<FabricSelect />} />
              <Route exact path='/profile' element={<UserProfile />} />

              <Route exact path='/reports' element={<MainReport />} />
              <Route exact path='/dashboard' element={<DashBoard />} />


              <Route exact path='/review' element={<ReviewOrders />} />
              <Route exact path='/approve' element={<ApproveOrders />} />
              <Route exact path='/received' element={<ReceivedOrders />} />




              {/* <Route exact path='/neworder' element={<OrderSteps />} /> */}

              <Route exact path='/neworder' element={<SelectClient />} />
              <Route exact path='/selectclient' element={<SelectClient />} />
              <Route exact path='/shirtmeasure' element={<ShirtMeasure />} />
              <Route exact path='/selectfabric' element={<SelectFabric />} />
              <Route exact path='/designshirt' element={<DesignShirt />} />
              <Route exact path='/orderinfo' element={<OrderInfo2 />} />
              <Route exact path='/ordersubmit' element={<NewOrderSubmit />} />
              <Route exact path='/submitresult' element={<SubmitResult />} />



              <Route exact path='/syslog' element={<SyslogView />} />



            </Route>
          </Routes>
        </AuthProvider>
      </Fragment>

    </Router>
  );
};

function App() {
  return (
    <div>

      <MainApp />
    </div>
  );
}

export default App;
