

import React, { useState } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Tabs from '@mui/material/Tabs';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';

import { useAuth } from "../../../hooks/AuthProvider";
import { getHeaders } from '../../../utils/apis';


import ClientsMan from '../clientman/ClientsMan';
import { Margin, Padding } from '@mui/icons-material';
import ClientOrders from '../clientorders/ClientOrders';
import ClientMeasurements from '../clientmeasure/clientmeasure';
import ReactFlagsSelect from "react-flags-select";
import './ClientInfo.css';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;


    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ClientProfile() {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [clientData, setClientData] = useState({});

    const [countrySel, setCountrySel] = useState("VN");

    const [tabValue, setTabValue] = useState(0);

    const auth = useAuth();
    const childRef = useRef();

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const selectClient = (data, next) => {
        setClientData({ ...data });
        setValue("client_id", data['client_id']);
        setValue("first_name", data['first_name']);
        setValue("last_name", data['last_name']);
        setValue("full_name", data['full_name']);
        setValue("ref_number", data['ref_number']);
        setValue("nickname", data['nickname']);
        setValue("email", data['email']);
        setValue("telephone", data['telephone']);
        setValue("dob", data['dob']);
        setValue("country", data['country']);
        setValue("address1", data['address1']);
        setValue("remark", data['remark']);
        setValue("state", data['state']);

        childRef.current?.callReloadData(data['client_id']);
        //  console.debug("DEBUG:", data);

    }

    const onSubmit = (data) => {

        if (!auth.user) {
            auth.logOut();
            return;
        }


        // data.preventDefault();
        data['country'] = countrySel;
        data['tailorshop_id'] = auth.user['tailorshop_id'];
        data['owner'] = auth.user.usr_id;
        data['client_id'] = clientData.client_id;

        // Object.keys(data).forEach((k) => data[k] === "" && delete data[k]);

        //console.debug("DEBUG:", data);

        fetch("/api/updateclients", {
            method: "POST",
            headers: getHeaders(auth),
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((rowData) => {
                if (rowData["result"]) {

                    if (rowData["result"] === "timeout") {
                        auth.logOut();
                    } else if (rowData["result"] === "OK") {
                        alert("Update client successful");
                    } else {
                        alert("Error when update DB. Check value or connection.");
                    }
                } else {

                    alert("No data");

                }

            })
            .catch((error) => { console.log(error); alert("Error call API"); });

        //console.log(data);
    }


    return (
        <div style={{ margin: "30px" }}>
            <ClientsMan selectClient={selectClient} />
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Profile" {...a11yProps(0)} style={{ textTransform: 'none', }} />
                        <Tab label="Order List" {...a11yProps(1)} style={{ textTransform: 'none', }} />
                        <Tab label="Measurements" {...a11yProps(2)} style={{ textTransform: 'none', }} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={tabValue} index={0}>
                    <h3> Profile</h3>
                    <div className="d-flex justify-content-center">


                        <form onSubmit={handleSubmit(onSubmit)}>

                            <Container className="p-9 " >
                                <Row style={{ width: "100%" }}>

                                    <i> <strong> Main information:</strong></i>
                                </Row>
                                <Row>
                                    <div className="mb-3">
                                        <label>Client Name *:</label>&nbsp;<br />
                                        <input type="text" placeholder="Full name" {...register("full_name", { required: true })} />
                                    </div>
                                </Row>
                                <Row style={{ width: "100%" }}>
                                    <Col md="6">
                                        <div className="mb-3">
                                            <label>Reference Number:</label>&nbsp;<br />
                                            <input type="text" placeholder="Client Reference Number" {...register("ref_number", {})} />
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3">
                                            <label>Client ID:</label>&nbsp;<br />
                                            <input type="text" placeholder="client_id" {...register("client_id", { required: true, disabled: true })} />
                                        </div>
                                    </Col>

                                </Row>
                                <Row style={{ width: "100%" }}>
                                    <div className="mb-3">
                                        <label>Remark:</label>&nbsp;<br />
                                        <textarea id="remark" name="remark" rows="1" cols="42" {...register("remark", {})}>
                                        </textarea>
                                    </div>

                                </Row>
                                <Row style={{ width: "100%" }}>
                                    <div className="mb-3">
                                        <label>Shipping Address:</label>&nbsp;<br />

                                        <textarea id="w3review" name="w3review" rows="2" cols="42" {...register("address1", {})}>
                                        </textarea>
                                    </div>
                                </Row>
                                <Row style={{ width: "100%" }}>
                                    <hr />
                                    <i> <strong>Additional information: </strong></i>
                                </Row>
                                <Row style={{ width: "100%" }}>
                                    <Col md="6">
                                        <div className="mb-3">
                                            <label>First Name:</label>&nbsp;<br />
                                            <input type="text" placeholder="First name" {...register("first_name", { required: false })} />
                                        </div>

                                        <div className="mb-3">
                                            <label>Date of birth:</label>&nbsp;<br />
                                            <input type="date" placeholder="dob" {...register("dob", {})} />


                                        </div>
                                        <div className="mb-3">
                                            <label>Email:</label>&nbsp;<br />
                                            <input type="email" placeholder="Email" {...register("email")} />
                                        </div>

                                        <div className="mb-3">
                                            <label>Status:</label>&nbsp;<br />
                                            <select {...register("state")} className="form-select">
                                                <option value="1">Active</option>
                                                <option value="2">Lock</option>
                                                {/* <option value="3">Wait approved</option> */}
                                                <option value="9">Deleted</option>
                                            </select>
                                        </div>


                                    </Col>
                                    <Col md="6">
                                        <div className="mb-3">
                                            <label>Last Name:</label>&nbsp;<br />
                                            <input type="text" placeholder="Last name" {...register("last_name", { required: false })} />
                                        </div>
                                        <div className="mb-3">
                                            <label>Username: (for app)</label>&nbsp;<br />
                                            <input type="text" placeholder="User name" {...register("nickname", {})} />
                                        </div>

                                        <div className="mb-3">
                                            <label>Telephone:</label>&nbsp;<br />
                                            <input type="text" placeholder="Telephone" {...register("telephone", {})} />
                                        </div>

                                        <label>Country:</label>&nbsp;<br />
                                        <ReactFlagsSelect
                                            className="mb-3"
                                            //   countries={["US", "GB", "FR", "DE", "IT", "VN"]}
                                            selected={countrySel}
                                            onSelect={(code) => setCountrySel(code)}
                                            fullWidth={false}
                                            style={{ width: "150px" }}
                                        />

                                    </Col>
                                </Row>
                                <Row>

                                </Row>

                            </Container>

                            <button type="submit" className="btn btn-success">Update</button>
                        </form>
                    </div>
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={1} >
                    <h3> Order list</h3>
                    <ClientOrders clientData={clientData} ref={childRef} />
                </CustomTabPanel>
                <CustomTabPanel value={tabValue} index={2}>
                    <h3> Measurements</h3>
                    <ClientMeasurements clientData={clientData} ref={childRef} />

                </CustomTabPanel>

            </Box>




        </div >
    );
}




